import eventsHandler from '@/services/common/eventsHandler.js'

class colorLineForm extends eventsHandler {
  constructor() {
    super()
    this._colorLine = {}
    this._valid = false
  }

  get colorLine() {
    const colorLine = {
      ...this._colorLine
    }
    return colorLine
  }

  get valid() {
    return this._valid
  }

  get isNew() {
    return !this._colorLine.id
  }

  setValid(valid) {
    this._valid = valid
    this._executeCallbacksOf('valid-change', valid)
  }

  resetColorLine() {
    this._colorLine = {}
    this._executeCallbacksOf('reset')
    this._executeCallbacksOf('update', {colorLine: {}})
  }

  updateField(key, value) {
    this._colorLine[key] = value
    this._executeCallbacksOf('update', {colorLine: this._colorLine})
    this._executeCallbacksOf('update-' + key, {key: this._colorLine[key]})
    this._executeCallbacksOf('update-is-new', this.isNew)
  }

  updateObject(colorLine) {
    this._colorLine = colorLine
    this._executeCallbacksOf('update', {colorLine: this._colorLine})
    this._executeCallbacksOf('update-is-new', this.isNew)
  }
}

export default new colorLineForm()